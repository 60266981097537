/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, {
  isValidElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { GlobalStyle } from 'styles/global-styles';

import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import { LoginPage } from './pages/Login/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { AccessDeniedPage } from './pages/AccessDenied/loadable';
import { useTranslation } from 'react-i18next';
import PrivateRoute from './components/PrivateRoute';
import { DashboardPage } from './pages/Dashboard/Loadable';
import { MainNav } from './components/MainNav';
import LogoutPage from './pages/Logout';
import styled from 'styled-components/macro';
import { AppHeader } from './components/AppHeader';
import { AuditPage } from './pages/Audit/Loadable';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAuthToken,
  selectAuthUser,
  selectMainMenuSize,
} from './slices/auth/selectors';
import { RoundTrips } from './pages/RoundTrips/Loadable';
import { SigninPage } from './pages/Signin/Loadable';
import { selectIsZoomedIn } from './slices/tools/selectors';
import { SignUpNamePage } from './pages/SignupName/Loadable';
import { SignUpEmailPage } from './pages/SignUpEmail/Loadable';
import { VerificationLink } from './pages/VerificationLink';
import { SignUpDetailsPage } from './pages/SignUpDetails/Loadable';

import { JoinWorkspace } from './pages/JoinWorkspace/Loadable';
import { ResetPasswordPage } from './pages/ResetPassword/Loadable';
import { ResetPasswordOtpPage } from './pages/ResetPasswordOTP/Loadable';
import { VehiclesPage } from './pages/Vehicles/Loadable';
import { CustomersPage } from './pages/Customers/Loadable';
import { CustomerPublic } from './pages/CustomerPublic';
import { OrdersPage } from './pages/Orders/Loadable';
import { InboxPage } from './pages/Inbox/Loadable';
import { StorageLocationPage } from './pages/StorageLocations/Loadable';
import { StorageLoadingInfoPage } from './pages/StorageLocations/Components/StoragLocationInfo/Loadable';
import { ClientGroupsPage } from './pages/ClientGroups/Loadable';
import { PricingGroupsPage } from './pages/PricingGroups/Loadable';

import {
  ANALYTICS,
  AUDIT,
  CUSTOMER,
  CUSTOMER_PUBLIC,
  DASHBOARD,
  LOGIN,
  LOGOUT,
  NOT_FOUND,
  ORDERS,
  OTP,
  RESET,
  ROUNDTRIP_ADD,
  SIGNIN,
  SIGNUP,
  SIGNUP_DESCRIPTION,
  SIGNUP_DESCRIPTION_DETAILS,
  SIGNUP_NAME,
  UPLOAD_FILES,
  SUPPORTUNIT,
  VEHICLES,
  VERIFICATION_LINK,
  CHECK_DOCUMENTS,
  COMPANYPROFIL,
  INBOX,
  ROUNDTRIP,
  SCHEDULED_ROUNDTRIPS,
  ALL_ROUNDTRIPS,
  ORDORIA,
  STORAGELOCATIONS,
  ORDERS_ACTIONS,
  LANGUAGE,
  SETTINGS_USERS,
  ACCESSORIES,
  ACCESSORYINFO,
  TOOLS,
  DASHBOARD_ALL,
  PAYMENTCARDS,
  VIEWCUSTOMER,
  ORDORIA_VIEWCUSTOMER,
  ROLES_PERMISSIONS,
  SETTINGS_CUSTOMIZATION,
  REPORTS,
  ORDORIA_CUSTOMER,
  STOCKEE_CUSTOMER,
  STOCKEE_CLIENT_GROUP,
  STOCKEE_PRICING_GROUP,
  REPORTS_ALL,
  PRIVACY_POLICY,
  CONTACT_US,
  USERINFO,
  EMPLOYEEINFO,
  CUSTOMERBRANCH,
  USERPAGE,
  SETTINGS,
  STORAGELOCATIONINFO,
  ACCESS_DENIED,
  PAYMENTS,
  IAM,
  STOCKEE_VIEWCUSTOMER,
  SETTINGS_ROUNDTRIP_MAPPING,
  STOCKEE_BALANCE,
} from 'utils/routes';
import { UploadFiles } from './pages/UploadFilesPage/Loadable';
import { AddRoundTrips } from './pages/AddRoundTrips';
import { AnalyticsPage } from './pages/Analytics/Loadable';
import { SupportUnits } from './pages/SupportUnits/Loadable';
import { CheckDocuments } from './pages/CheckDocuments';
import { AllRoundTrips } from './pages/AllRoundtrip/Loadable';
import {
  selectLastToasterNotification,
  selectLastUserNotification,
} from '../common/store/app/selectors';
import { appActions } from '../common/store/app';
import { CompanyProfile } from './pages/Settings/CompanyProfile';
import { Alert, Portal } from '@mui/material';
import { RoundtripsTabs } from './pages/RoundtripsTabs/Loadable';
import { OrdersActionPage } from './pages/Ordoria/Orders/Loadable';
import {
  ORDORIA_CREATE_ORDERS,
  ORDORIA_VIEW_ODER_DETAIL,
  ORDORIA_SALES_VIEW_ORDER,
  ORDORIA_CALL_PLAN,
  ORDORIA_CATALOG,
} from './pages/Ordoria/routes';
import { OrdoriaCreateOrdersPage } from './pages/Ordoria/CreateOrders/Loadable';
import { Settings } from './pages/Settings';
import { LanguageCurrency } from './pages/Settings/LanguageCurrency/Loadable';
import { OrdoriaDetailPage } from './pages/Ordoria/OrderDetail/Loadable';
import { UsersPage as UsersPageSettings } from './pages/Settings/Users/Loadable';
import { CustomizationPage } from './pages/Settings/Customization/Loadable';
import { UsersPage } from './pages/Users/Loadable';
import { SalesOrderDetails } from './pages/Ordoria/SalesOrderDetail';
import { OrdoriaCallPlan } from './pages/Ordoria/CallPlan/Loadable';
import { CatalogPage } from './pages/Ordoria/Catalog/Loadable';
import { OrdoriaPortalDahsboardPage } from './pages/Ordoria/Portal/dashboard/Loadable';
import { Accessories } from './pages/Accessories/Loadable';
import { AccessoryInfo } from './pages/AccessoryInfo/Loadable';
import { Tools } from './pages/Tools/Loadable';
import { PaymentCards } from './pages/PaymentCards/Loadable';
import { ViewCustomer } from './pages/Customers/components/ViewCustomer';
import { BalancePage } from './pages/Stockee/Balance/Loadable';
import { GridProvider } from './components/Grid/context';
import { RolesPermissions } from './pages/Settings/RolesPermissions/Loadable';
import { RoleProvider } from './pages/Settings/RolesPermissions/context';

import { ReportsPage } from './pages/Reports/Loadable';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { ContactUs } from './pages/ContactUs';
import { UserProvider } from './pages/Settings/Users/context';
import { UserInfo } from './pages/Settings/UserInfo/Loadable';
import { AiControlCenterPage } from './pages/AiControlCenter/Loadable';
import { useGetConfigurationQuery } from 'common/services/organizationApi';
import { CustomerBranch } from './pages/Customers/components/CustomerBranch';
import { useInvalidateUserData } from 'hooks/Users/useInvalidateUser';
import { AbilityProvider } from 'hooks/Abilities/context';
import { ApplicationRoute } from './components/ApplicationRoute';
import api from 'common/services/api';
import { selectOrganization } from 'common/store/organization/selectors';
import VoiceCall from './components/VoiceCall';

import BellNotificationSound from 'assets/sounds/chat/BellNotificationSound.webm';
import { Notification } from './components/Notification';
import { useUserInteracted } from 'hooks/userInteracted';
import { useUserNotifications } from 'hooks/Users/useUserNotification';
import { Payments } from './pages/Payments';
import { ModalProvider } from './components/Dialog/context';
import RoundtripMapping from './pages/Settings/RoundtripMapping';
import { OrderDetails } from './components/OrderDetails';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export function App() {
  const { i18n } = useTranslation();
  const authToken = useSelector(selectAuthToken);
  const authUser = useSelector(selectAuthUser);
  const zoomedIn = useSelector(selectIsZoomedIn);
  const lastToasterNotification = useSelector(selectLastToasterNotification);
  const dispatch = useDispatch();
  const i18nLanguage = i18n.language;
  const mainMenuSize = useSelector(selectMainMenuSize);
  const isMinify = mainMenuSize === 'minify';
  const organization = useSelector(selectOrganization);
  const lastUserNotification = useSelector(selectLastUserNotification);
  const notificationAudioRef: React.LegacyRef<HTMLAudioElement> = useRef(null);
  const [objectId, setObjectId] = useState('');
  const [openOrderDetailsDrawer, setOpenOrderDetailsDrawer] =
    useState<boolean>(false);

  useGetConfigurationQuery();
  useUserNotifications();
  useInvalidateUserData();

  const userInteracted = useUserInteracted();

  const handleCloseNotification = () => {
    dispatch(appActions.cleanLastToasterNotification());
  };

  const handleCloseUserNotification = () => {
    dispatch(appActions.cleanLastUserNotification());

    if (!!notificationAudioRef?.current && userInteracted) {
      if (notificationAudioRef?.current?.currentTime > 1) {
        notificationAudioRef?.current?.pause();
      }
      notificationAudioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (
      !!lastUserNotification &&
      !!notificationAudioRef?.current &&
      userInteracted
    ) {
      try {
        if (notificationAudioRef?.current?.currentTime > 1) {
          notificationAudioRef?.current?.pause();
        } else {
          notificationAudioRef.current.currentTime = 0;
          notificationAudioRef?.current?.play();
        }
      } catch (e) {}
    }
  }, [lastUserNotification]);

  useEffect(() => {
    if (authUser?.language && authUser.language !== i18nLanguage) {
      i18n.changeLanguage(authUser?.language);
    }

    if (authUser?.language) {
      window.dayjs.locale(authUser?.language);
    }
  }, [authUser?.language]);

  useEffect(() => {
    if (
      !organization?._id ||
      !authUser?._currentOrganization?._id ||
      authUser?._currentOrganization._id === organization._id
    ) {
      return;
    }
    dispatch(api.util.invalidateTags(['OrganizationConfiguration']));
    dispatch(
      appActions.setLastToasterNotification({
        duration: 5000,
        severity: 'info',
        message: i18n.t('auth.organizationSwitch.success', {
          organizationName: authUser?._currentOrganization.name,
        }),
      }),
    );
  }, [authUser?._currentOrganization, dispatch, i18n, organization?._id]);

  useEffect(() => {
    if (authUser?._id) {
      Sentry.setUser({
        id: authUser._id,
        email: authUser.email,
        segment: authUser._currentOrganization.customer ? 'customer' : 'user',
        isSuperAdmin: authUser.isSuperAdmin,
        currentOrganization: authUser._currentOrganization?.name,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [authUser?._id]);

  const authCustomer = authUser?.currentAgent?._customer;

  const inSettings = window.location.pathname?.includes(SETTINGS);
  const inJoinWorkspace = window.location.pathname?.includes('/join');

  const lastUserNotificationRef = useRef<any>(null);
  useEffect(() => {
    if (lastUserNotification && lastUserNotification !== null) {
      lastUserNotificationRef.current = lastUserNotification;
      setObjectId(lastUserNotificationRef?.current?.objectId);
    }
  }, [lastUserNotification]);

  const openOrderDetail = () => {
    setOpenOrderDetailsDrawer(true);
  };
  const currentPath = window.location.pathname;
  const excludePaths = useMemo(() => [ROUNDTRIP_ADD], []);
  const showNavMenu = useMemo(() => {
    return (
      authToken &&
      !zoomedIn &&
      !inJoinWorkspace &&
      !excludePaths.includes(currentPath)
    );
  }, [authToken, zoomedIn, inJoinWorkspace, currentPath, excludePaths]);

  const showAppHeader = useMemo(() => {
    return (
      authToken &&
      (!zoomedIn || !!inSettings) &&
      !inJoinWorkspace &&
      !excludePaths.includes(currentPath)
    );
  }, [
    authToken,
    zoomedIn,
    inJoinWorkspace,
    currentPath,
    excludePaths,
    inSettings,
  ]);

  // @ts-ignore
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Bamptee"
        defaultTitle="Bamptee"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <AbilityProvider>
        <ModalProvider>
          <Wrapper>
            {showNavMenu ? <MainNav /> : null}
            <AppWrapper>
              {showAppHeader ? <AppHeader /> : null}
              <AppContent showNav={!zoomedIn}>
                <Routes>
                  <Route path={LOGIN} element={<LoginPage />} />
                  <Route path={SIGNIN} element={<SigninPage />} />
                  <Route path={SIGNUP} element={<SignUpEmailPage />} />
                  <Route
                    path={VERIFICATION_LINK}
                    element={<VerificationLink />}
                  />
                  <Route path={SIGNUP_NAME} element={<SignUpNamePage />} />
                  <Route
                    path={SIGNUP_DESCRIPTION}
                    element={<JoinWorkspace />}
                  />
                  <Route
                    path={SIGNUP_DESCRIPTION_DETAILS}
                    element={<SignUpDetailsPage />}
                  />
                  <Route path={OTP} element={<ResetPasswordOtpPage />} />
                  <Route path={RESET} element={<ResetPasswordPage />} />
                  <Route path={LOGOUT} element={<LogoutPage />} />

                  <Route
                    path="ai-control-center"
                    element={
                      <PrivateRoute>
                        <AiControlCenterPage />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path={CUSTOMER_PUBLIC}
                    element={<CustomerPublic defaultToken={null} />}
                  />

                  <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
                  <Route path={CONTACT_US} element={<ContactUs />} />

                  {/* IDM Routes*/}
                  <Route element={<ApplicationRoute application="idm" />}>
                    <Route path="settings" element={<Settings />}>
                      <Route
                        index
                        path={COMPANYPROFIL}
                        element={
                          <PrivateRoute>
                            <CompanyProfile />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={LANGUAGE}
                        element={
                          <PrivateRoute>
                            <LanguageCurrency />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={SETTINGS_USERS}
                        element={
                          <PrivateRoute>
                            <UserProvider>
                              <UsersPageSettings />
                            </UserProvider>
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={USERINFO}
                        element={
                          <PrivateRoute>
                            <UserInfo />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={ROLES_PERMISSIONS}
                        element={
                          <PrivateRoute>
                            <GridProvider>
                              <RoleProvider>
                                <RolesPermissions />
                              </RoleProvider>
                            </GridProvider>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={SETTINGS_CUSTOMIZATION}
                        element={
                          <PrivateRoute>
                            <CustomizationPage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={SETTINGS_ROUNDTRIP_MAPPING}
                        element={
                          <PrivateRoute>
                            <RoundtripMapping />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route
                      path={ACCESS_DENIED}
                      element={<AccessDeniedPage />}
                    />

                    <Route path={NOT_FOUND} element={<NotFoundPage />} />
                  </Route>

                  {/* Bianta Routes */}
                  <Route element={<ApplicationRoute application="bianta" />}>
                    <Route
                      path={UPLOAD_FILES}
                      element={
                        <PrivateRoute>
                          <UploadFiles />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={CHECK_DOCUMENTS}
                      element={
                        <PrivateRoute>
                          <CheckDocuments />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={PAYMENTS}
                      element={
                        <PrivateRoute>
                          <Payments />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={DASHBOARD_ALL}
                      element={
                        <PrivateRoute>
                          <DashboardPage allOrganizations />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={AUDIT}
                      element={
                        <PrivateRoute>
                          <AuditPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={ROUNDTRIP}
                      element={
                        <PrivateRoute>
                          <RoundtripsTabs />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path={SCHEDULED_ROUNDTRIPS}
                        element={
                          <PrivateRoute>
                            <RoundTrips />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={ALL_ROUNDTRIPS}
                        element={
                          <PrivateRoute>
                            <AllRoundTrips />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                    <Route
                      path={SUPPORTUNIT}
                      element={
                        <PrivateRoute>
                          <SupportUnits />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={ANALYTICS}
                      element={
                        <PrivateRoute>
                          <AnalyticsPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={INBOX}
                      element={
                        <PrivateRoute>
                          <InboxPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={ACCESSORIES}
                      element={
                        <PrivateRoute>
                          <Accessories />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`${ACCESSORYINFO}`}
                      element={
                        <PrivateRoute>
                          <AccessoryInfo />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={TOOLS}
                      element={
                        <PrivateRoute>
                          <Tools />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={PAYMENTCARDS}
                      element={
                        <PrivateRoute>
                          <PaymentCards />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={VIEWCUSTOMER}
                      element={
                        <PrivateRoute>
                          <ViewCustomer />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={REPORTS}
                      element={
                        <PrivateRoute>
                          <ReportsPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={REPORTS_ALL}
                      element={
                        <PrivateRoute>
                          <ReportsPage allOrganizations />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={ROUNDTRIP_ADD}
                      element={
                        <PrivateRoute>
                          <AddRoundTrips />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={USERPAGE}
                      element={
                        <PrivateRoute>
                          <UsersPage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={`/${EMPLOYEEINFO}`}
                      element={
                        <PrivateRoute>
                          <UserInfo />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={`${VEHICLES}/logistic`}
                      element={
                        <PrivateRoute>
                          <VehiclesPage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={`${VEHICLES}/sales`}
                      element={
                        <PrivateRoute>
                          <VehiclesPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={ORDERS}
                      element={
                        <PrivateRoute>
                          <OrdersPage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={CUSTOMER}
                      element={
                        <PrivateRoute>
                          <CustomersPage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={DASHBOARD}
                      element={
                        <PrivateRoute>
                          <DashboardPage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={CUSTOMERBRANCH}
                      element={
                        <PrivateRoute>
                          <CustomerBranch />
                        </PrivateRoute>
                      }
                    />

                    <Route path={NOT_FOUND} element={<NotFoundPage />} />

                    <Route
                      path={ACCESS_DENIED}
                      element={<AccessDeniedPage />}
                    />
                  </Route>

                  {/* Ordoria Routes */}
                  <Route element={<ApplicationRoute application="ordoria" />}>
                    <Route path={ORDORIA}>
                      <Route
                        index
                        element={
                          <PrivateRoute>
                            {authCustomer ? (
                              <OrdoriaPortalDahsboardPage />
                            ) : (
                              <DashboardPage />
                            )}
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="portal"
                        element={
                          <PrivateRoute>
                            {authCustomer ? (
                              <CustomerPublic
                                defaultToken={authCustomer?.token}
                              />
                            ) : null}
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={ORDORIA_CALL_PLAN}
                        element={
                          <PrivateRoute>
                            <OrdoriaCallPlan />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={ORDORIA_CATALOG}
                        element={
                          <PrivateRoute>
                            <CatalogPage />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                    <Route
                      path={ORDERS_ACTIONS}
                      element={
                        <PrivateRoute>
                          <OrdersActionPage />
                        </PrivateRoute>
                      }
                    />
                    <Route index={false} path="ordoria/orders">
                      <Route
                        path={ORDORIA_CREATE_ORDERS}
                        element={
                          <PrivateRoute>
                            <OrdoriaCreateOrdersPage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={ORDORIA_VIEW_ODER_DETAIL}
                        element={
                          <PrivateRoute>
                            <OrdoriaDetailPage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={ORDORIA_SALES_VIEW_ORDER}
                        element={
                          <PrivateRoute>
                            <SalesOrderDetails />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route
                      path={`${ORDORIA}${DASHBOARD_ALL}`}
                      element={
                        <PrivateRoute>
                          <DashboardPage allOrganizations />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={ORDORIA_VIEWCUSTOMER}
                      element={
                        <PrivateRoute>
                          <ViewCustomer />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={ORDORIA_CUSTOMER}
                      element={
                        <PrivateRoute>
                          <CustomersPage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={`/ordoria${REPORTS}`}
                      element={
                        <PrivateRoute>
                          <ReportsPage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={`/ordoria${REPORTS_ALL}`}
                      element={
                        <PrivateRoute>
                          <ReportsPage allOrganizations />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={ACCESS_DENIED}
                      element={<AccessDeniedPage />}
                    />

                    <Route path={NOT_FOUND} element={<NotFoundPage />} />
                  </Route>
                  {/* STOCKEE Routes */}
                  <Route element={<ApplicationRoute application="stockee" />}>
                    <Route
                      path={STORAGELOCATIONS}
                      element={
                        <PrivateRoute>
                          <GridProvider>
                            <StorageLocationPage />
                          </GridProvider>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={STORAGELOCATIONINFO}
                      element={
                        <PrivateRoute>
                          <GridProvider>
                            <StorageLoadingInfoPage />
                          </GridProvider>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={STOCKEE_BALANCE}
                      element={
                        <PrivateRoute>
                          <BalancePage />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={STOCKEE_CUSTOMER}
                      element={
                        <PrivateRoute>
                          <CustomersPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={STOCKEE_CLIENT_GROUP}
                      element={
                        <PrivateRoute>
                          <ClientGroupsPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={STOCKEE_PRICING_GROUP}
                      element={
                        <PrivateRoute>
                          <PricingGroupsPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={STOCKEE_VIEWCUSTOMER}
                      element={
                        <PrivateRoute>
                          <ViewCustomer />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path={ACCESS_DENIED}
                      element={<AccessDeniedPage />}
                    />
                  </Route>

                  {/* IAM Routes */}
                  <Route element={<ApplicationRoute application="iam" />}>
                    <Route path={IAM}>
                      <Route
                        path={USERPAGE}
                        element={
                          <PrivateRoute>
                            <UsersPage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={EMPLOYEEINFO}
                        element={
                          <PrivateRoute>
                            <UserInfo />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                    <Route
                      path={ACCESS_DENIED}
                      element={<AccessDeniedPage />}
                    />
                  </Route>
                </Routes>

                {authUser && isEmpty(authUser?.currentAgent?._customer) && (
                  <VoiceCall authUser={authUser} />
                )}

                {lastToasterNotification ? (
                  <Portal>
                    <CustomSnackBar
                      open
                      autoHideDuration={lastToasterNotification.duration}
                      onClose={handleCloseNotification}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      TransitionComponent={SlideTransition}
                      message={lastToasterNotification.message}
                      showNav={!zoomedIn}
                      isMinify={isMinify}
                    >
                      <Alert
                        variant="standard"
                        onClose={handleCloseNotification}
                        severity={lastToasterNotification.severity}
                      >
                        {(!!lastToasterNotification.content &&
                          isValidElement(lastToasterNotification.content) &&
                          lastToasterNotification.content) ||
                          lastToasterNotification.message}
                      </Alert>
                    </CustomSnackBar>
                  </Portal>
                ) : null}

                {lastUserNotification?.content ? (
                  <Portal>
                    <CustomSnackBar
                      open
                      onClose={handleCloseUserNotification}
                      autoHideDuration={lastUserNotification.duration}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      TransitionComponent={SlideTransition}
                      showNav={!zoomedIn}
                      isMinify={isMinify}
                      sx={{ position: 'absolute' }}
                    >
                      <div>
                        {lastUserNotification?.activityName ===
                        'order-status-updated' ? (
                          <Notification
                            onClose={handleCloseUserNotification}
                            content={lastUserNotification.content}
                            url={lastUserNotification.url}
                            activityName={lastUserNotification.activityName}
                            openOrderDetail={openOrderDetail}
                            activityKey={lastUserNotification?.activityKey}
                          />
                        ) : (
                          <Notification
                            onClose={handleCloseUserNotification}
                            content={lastUserNotification.content}
                            url={lastUserNotification.url}
                            activityName={lastUserNotification.activityName}
                            activityKey={lastUserNotification.activityKey}
                          />
                        )}
                      </div>
                    </CustomSnackBar>
                  </Portal>
                ) : null}
                <OrderDetails
                  open={openOrderDetailsDrawer}
                  onClose={() => {
                    setOpenOrderDetailsDrawer(false);
                    setObjectId('');
                  }}
                  orderId={objectId}
                />
                <audio src={BellNotificationSound} ref={notificationAudioRef} />
              </AppContent>
            </AppWrapper>
          </Wrapper>
        </ModalProvider>
      </AbilityProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: auto;
  background: ${(props) => props.theme.roundtripGreyBg};
`;

const AppWrapper = styled.div`
  height: 100vh;
  width: 100%;
  z-index: 0;
  overflow: hidden;
`;

interface AppContentProps {
  showNav: boolean;
  isMinify?: boolean;
}
const AppContent = styled.div<AppContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding-top: ${(p) => (p.showNav ? p.theme.topBarHeight : 'unset')};
  position: relative;
`;

const CustomSnackBar = styled(Snackbar)<AppContentProps>((props) => ({
  top: props.showNav
    ? `calc(${props.theme.topBarHeight} + 2px) !important`
    : `calc(${props.theme.tabsBarHeight} + 2px) !important`,
  marginRight: '-6px',
}));
