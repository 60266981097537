// export const ROUNDTRIP = '/roundtrips';
export const LOGIN = '/login';
export const REOPTIMIZE = '/reoptimize';
export const SIGNIN = '/signin';
export const CUSTOMER_PUBLIC = '/portal/:token';
export const SIGNUP = '/signup';
export const VERIFICATION_LINK = '/signup/verificationlink';
export const SIGNUP_NAME = '/signup/sitename';
export const SIGNUP_DESCRIPTION = '/join/:token';
export const SIGNUP_DESCRIPTION_DETAILS = '/signup/details/:email/:name';
export const OTP = '/resetpasswordotp';
export const RESET = '/resetpassword';
export const LOGOUT = '/logout';
export const USERS = '/users';
export const VEHICLES = '/vehicles';
export const VEHICLES_LOGISTIC = '/vehicles/logistic';
export const VEHICLES_SALES = '/vehicles/sales';
export const CUSTOMER = '/customers';
export const ORDORIA_CUSTOMER = '/ordoria/customers';
export const ORDERS = '/orders';
export const ORDERS_DETAILS = '/orderDetails/:orderId';
export const AUDIT = '/audit';
export const ROUNDTRIP_ADD = '/roundtrip/add';
export const NOT_FOUND = '*';
export const UPLOAD_FILES = '/upload-files';
export const ROUNDTRIP = '/roundtrips';
export const ANALYTICS = '/analytics';
export const DASHBOARD = '/';
export const DASHBOARD_ALL = '/dashboard-all';
export const CHECK_DOCUMENTS = '/customers/check-documents';
export const PAYMENTS = '/customers/payments/:status';
export const PAYMENTS_ROOT = '/customers/payments';
export const ALLROUNDTRIP = '/allroundtrip';
export const SETTINGS = '/settings';
export const SUPPORTUNIT = '/support-unit';
export const TOOLS = '/tools';
export const SALES = '/users/sales';
export const DRIVERS = '/users/driver';
export const INTERESTPOINT = '/point-of-interest';
export const SUPPLIERS = '/suppliers';
export const SUPPLY = '/supply';
export const COMPANYPROFIL = 'company-profile';
export const LANGUAGE = 'language-currency';
export const INBOX = '/inbox';
export const SCHEDULED_ROUNDTRIPS = 'scheduled';
export const ALL_ROUNDTRIPS = 'all';
export const SETTINGS_COMPANY_PROFILE = '/settings/company-profile';
export const SETTINGS_LANGUAGE = '/settings/language-currency';
export const SETTINGS_USERS = '/settings/users';
export const SETTINGS_CUSTOMIZATION = '/settings/customization';
export const SETTINGS_ROUNDTRIP_MAPPING = '/settings/roundtrip-mapping';
export const ORDORIA = '/ordoria';
export const STOCKEE = '/stockee';
export const STORAGELOCATIONS = '/stockee/storage';
export const STOCKEE_CUSTOMER = '/stockee/clients';
export const STOCKEE_BALANCE = '/stockee/balance';
export const STOCKEE_CLIENT_GROUP = '/stockee/client-group';
export const STOCKEE_CUSTOMER_GROUP = '/stockee/client-group';
export const STOCKEE_DESCOUNT_GRID = '/stockee/discount_grid';
export const STOCKEE_STOCK = '/stockee/stock';
export const IAM = '/iam';
export const ORDERS_ACTIONS = '/ordoria/orders';
export const ACCESSORIES = '/accessories';
export const ACCESSORYINFO = '/:type/:id/info';
export const PAYMENTCARDS = '/payment-cards';
export const VIEWCUSTOMER = '/customers/:id/info';
export const ORDORIA_VIEWCUSTOMER = '/ordoria/customers/:id/details';
export const ROLES_PERMISSIONS = '/settings/roles-permissions';
export const REPORTS = '/reports';
export const REPORTS_ALL = '/reports-all';
export const PRIVACY_POLICY = '/privacy-policy';
export const CONTACT_US = '/contact-us';
export const USERINFO = '/settings/users/:id/info';
export const EMPLOYEEINFO = 'employee/:id/info';
export const CUSTOMERBRANCH = '/cutomerinfo/:id/branch';
export const USERPAGE = 'users/:role?';
export const BIANTA = '/bianta';
export const STORAGELOCATIONINFO = '/stockee/storage/:id';
export const ACCESS_DENIED = 'access-denied';
export const CATALOG = '/ordoria/catalog';
export const CALL_LIST = '/callplan';
export const NOTES = '/notes';
export const QUOTATIONS = '/quotations';
export const TRANSACTIONS = '/transaction';
export const PRODUCTS = '/products';
export const STOCKEE_VIEWCUSTOMER = '/stockee/clients/:id/info';
export const STOCKEE_PRICING_GROUP = '/stockee/pricing-group';

export const REDIRECTS = {
  Bamptee: {
    employee: USERS,
    accessories: ACCESSORIES,
    tools: TOOLS,
    paymentcards: PAYMENTCARDS,
    customers: CUSTOMER,
  },
};
